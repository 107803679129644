var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("div", { staticClass: "flex mb-4" }, [
        _c(
          "div",
          {
            staticClass:
              "p-3 rounded-lg cursor-pointer flex items-center justify-between font-medium text-base text-success border border-solid border-success",
            on: { click: _vm.subscribe },
          },
          [
            _c("feather-icon", {
              attrs: { icon: "PlusCircleIcon", svgClasses: "h-4 w-4" },
            }),
            _c("span", { staticClass: "ml-2 text-base text-success" }, [
              _vm._v(_vm._s(_vm.$t("inscrever"))),
            ]),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "ml-auto" },
          [
            _c("edu-binary-switch", {
              staticClass: "text-right items-end",
              attrs: {
                "flex-justify": "end",
                label: _vm.$t("modo-visualizacao"),
                "left-text": "Cartões",
                "right-text": "Tabela",
              },
              model: {
                value: _vm.viewMode,
                callback: function ($$v) {
                  _vm.viewMode = $$v
                },
                expression: "viewMode",
              },
            }),
          ],
          1
        ),
      ]),
      _c(
        "transition",
        {
          attrs: {
            duration: 500,
            mode: "out-in",
            tag: "div",
            name: "slide-fade",
          },
        },
        [
          _vm.layout === "cards"
            ? _c("index-grid-card-view", {
                attrs: { service: _vm.classService },
                on: {
                  enter: function ($event) {
                    _vm.view(_vm._.get($event, "id"))
                  },
                },
              })
            : _vm._e(),
          _vm.layout === "table"
            ? _c("Grid", {
                attrs: {
                  route_grid_path: "student/grid",
                  service: _vm.classService,
                  route_name: "classes",
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "gridData",
                      fn: function (props) {
                        return _vm._l(props.data, function (tr, indextr) {
                          return _c(
                            "vs-tr",
                            { key: indextr, attrs: { data: tr } },
                            [
                              _c(
                                "vs-td",
                                { key: "id", attrs: { width: "5%" } },
                                [
                                  _c("div", {
                                    directives: [
                                      {
                                        name: "html-safe",
                                        rawName: "v-html-safe",
                                        value: tr.id,
                                        expression: "tr.id",
                                      },
                                    ],
                                  }),
                                ]
                              ),
                              _c(
                                "vs-td",
                                { key: "title", attrs: { width: "25%" } },
                                [
                                  _c("div", {
                                    directives: [
                                      {
                                        name: "html-safe",
                                        rawName: "v-html-safe",
                                        value: tr.title,
                                        expression: "tr.title",
                                      },
                                    ],
                                  }),
                                ]
                              ),
                              _c(
                                "vs-td",
                                { key: "description", attrs: { width: "30%" } },
                                [
                                  _vm._v(
                                    _vm._s(tr.description ? tr.description : "")
                                  ),
                                ]
                              ),
                              _c(
                                "vs-td",
                                { key: "actions", attrs: { width: "5%" } },
                                [
                                  _c("feather-icon", {
                                    directives: [
                                      {
                                        name: "permission",
                                        rawName: "v-permission",
                                        value: "classes.students.menu",
                                        expression: "'classes.students.menu'",
                                      },
                                    ],
                                    attrs: {
                                      icon: "EyeIcon",
                                      svgClasses:
                                        "h-5 w-5 mb-1 mr-3 stroke-current text-warning",
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.view(props.data[indextr].id)
                                      },
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        })
                      },
                    },
                  ],
                  null,
                  false,
                  2906640137
                ),
              })
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }