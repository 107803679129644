<template>
  <div>
    <div class="flex mb-4">
      <div
        class="p-3 rounded-lg cursor-pointer flex items-center justify-between font-medium text-base text-success border border-solid border-success"
        @click="subscribe"
      >
        <feather-icon icon="PlusCircleIcon" svgClasses="h-4 w-4" />
        <span class="ml-2 text-base text-success">{{ $t('inscrever') }}</span>
      </div>
      <div class="ml-auto">
        <edu-binary-switch
          flex-justify="end"
          class="text-right items-end"
          :label="$t('modo-visualizacao')"
          v-model="viewMode"
          left-text="Cartões"
          right-text="Tabela"
        />
      </div>
    </div>

    <transition :duration="500" mode="out-in" tag="div" name="slide-fade">
      <index-grid-card-view v-if="layout==='cards'" @enter="view(_.get($event, 'id'))" :service="classService"/>
      <Grid v-if="layout==='table'" :route_grid_path="'student/grid'" :service="classService" route_name="classes">
        <template v-slot:gridData="props">
          <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in props.data">
            <vs-td width="5%" key="id">
              <div v-html-safe="tr.id"></div>
            </vs-td>
            <vs-td width="25%" key="title">
              <div v-html-safe="tr.title"></div>
            </vs-td>
            <vs-td width="30%" key="description">{{
              tr.description ? tr.description : ''
            }}</vs-td>
            <vs-td width="5%" key="actions">
                <feather-icon
                  v-permission="'classes.students.menu'"
                  icon="EyeIcon"
                  svgClasses="h-5 w-5 mb-1 mr-3 stroke-current text-warning"
                  @click="view(props.data[indextr].id)"
                />
            </vs-td>
          </vs-tr>
        </template>

      </Grid>
    </transition>
  </div>
</template>

<script>
import ClassService from '@/services/api/ClassService'
import IndexGridCardView from './IndexGridCardView.vue'
import EduBinarySwitch from '@/components/EduBinarySwitch.vue'

export default {
  components: { IndexGridCardView, EduBinarySwitch },
  computed: {
    layout() {
      return this.viewMode ? 'table' : 'cards'
    }
  },
  data: () => ({
    viewMode: false, // true = 'table', false = 'cards'
    selected: [],
    classService: null
  }),
  methods: {
    subscribe() {
      this.$router.push('/class_invite')
    },
    view(classId) {
      this.$router.push(`/classes_students/${classId}/overview`)
    }
  },
  beforeMount() {
    this.classService = ClassService.build(this.$vs)
  }
}
</script>
